import React from 'react';
import { translate } from 'react-i18next';
import VehicleDetailsCollapse from './VehicleDetailsCollapse';
import VehicleDetailsLabelAndValue from './VehicleDetailsLabelAndValue';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle, formatDate } from '../../../utils/utils';
import KeyValueEditorModal from '../../shared/KeyValueEditorModal';
import CBButtonGarageAction from '../../shared/CBButtonGarageAction';
import {
  updateOrganizationVehicle as _updateOrganizationVehicle,
} from '../../../actions/vehicleActions';
import { connect } from 'react-redux';

class VehicleDetailsBasicContent extends React.Component {
  state = {
    modalIsOpen: false,
  }

  onSubmitOrganizationData = (data) => {
    const { vehicle, updateOrganizationVehicle, selectedOrganizationId } = this.props;
    updateOrganizationVehicle(vehicle.id, {organization_data: {...vehicle.organization_data, data}}, selectedOrganizationId);
  }

  render() {
    const { t, vehicle } = this.props;
    const { modalIsOpen } = this.state;
    const insuranceString = `${vehicle.insurance_company} \
      ${formatDate(vehicle.insurance_start_date)}`;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'mainContentContainer')}>
            <VehicleDetailsCollapse open title={t('basicInfoHeading')}>
              <VehicleDetailsLabelAndValue
                label={t('manufacturerLabel')}
                value={vehicle.manufacturer}
              />
              {/* <VehicleDetailsLabelAndValue label={t('vehicleStateLabel')} value='Placeholder' /> */}
              <VehicleDetailsLabelAndValue
                label={t('vehicleClassLabel')}
                value={vehicle.vehicle_class}
              />
              <VehicleDetailsLabelAndValue
                label={t('productNumberLabel')}
                value={vehicle.platform_number}
              />
              <VehicleDetailsLabelAndValue
                label={t('initialRegistrationLabel')}
                value={formatDate(vehicle.initial_registration_date)}
              />
              <VehicleDetailsLabelAndValue label={t('typeNameLabel')} value={vehicle.type_name} />
              <VehicleDetailsLabelAndValue
                label={t('certTechnicalNumberLabel')}
                value={vehicle.cert_technical_running_number}
              />
              <VehicleDetailsLabelAndValue
                label={t('certReportNumberLabel')}
                value={vehicle.cert_report_running_number}
              />
              <VehicleDetailsLabelAndValue
                label={t('typeAcceptNumberLabel')}
                value={vehicle.type_accept_number}
              />
              <VehicleDetailsLabelAndValue label={t('variantLabel')} value={vehicle.variant} />
              <VehicleDetailsLabelAndValue label={t('versionLabel')} value={vehicle.version} />
              <VehicleDetailsLabelAndValue label={t('usageLabel')} value={vehicle.usage_display} />
              <VehicleDetailsLabelAndValue label={t('insurance')} value={insuranceString} />
              <VehicleDetailsLabelAndValue label={t('traficomSyncDate')} value={formatDate(vehicle.traficom_sync_date) || '-'} />
              <div style={getStyle(screenSize, styles, 'traficomSource')}>
                {t('traficomSource')}
              </div>
            </VehicleDetailsCollapse>

            <VehicleDetailsCollapse open={false} title={t('motorInfoHeading')}>
              <VehicleDetailsLabelAndValue
                label={t('engineCapacityLabel')}
                value={vehicle.engine_displacement}
              />
              <VehicleDetailsLabelAndValue
                label={t('engineMotivePowerLabel')}
                value={t(`motivePower:${vehicle.engine_motive_power}`)}
              />
              <VehicleDetailsLabelAndValue
                label={t('enginePowerLabel')}
                value={vehicle.engine_power}
              />
              <VehicleDetailsLabelAndValue
                label={t('enginePowerMassRatioLabel')}
                value={vehicle.engine_power_mass_ratio}
              />
              <VehicleDetailsLabelAndValue
                label={t('engineNoiseDriveByLabel')}
                value={vehicle.engine_noise_drive_by}
              />
              <VehicleDetailsLabelAndValue
                label={t('engineNoiseStaticLabel')}
                value={vehicle.engine_noise_static}
              />
              <div style={getStyle(screenSize, styles, 'traficomSource')}>
                {t('traficomSource')}
              </div>
            </VehicleDetailsCollapse>

            {
              !vehicle.is_personal_vehicle && (
                <VehicleDetailsCollapse open={false} title={t('organizationInfoHeading')}>
                  {
                    vehicle.organization_data && Object.entries(vehicle.organization_data.data).map(([key, value]) => (
                      <VehicleDetailsLabelAndValue
                        label={key}
                        value={value}
                      />
                    ))
                  }
                  <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                    <CBButtonGarageAction
                      mainIcon='/img/icon_edit.svg'
                      additionalStylesMainIcon={{ height: 25, width: 25 }}
                      text="Muokkaa dataa"
                      onClick={() => this.setState({ modalIsOpen: true })}
                      />
                  </div>
                </VehicleDetailsCollapse>
              )
            }
            {
              modalIsOpen && (
                <KeyValueEditorModal
                  modalIsOpen={modalIsOpen}
                  closeModal={() => {this.setState({ modalIsOpen: false });}}
                  afterOpenModal={() => {}}
                  title="Muokkaa yrityksen ajoneuvodataa"
                  initialData={vehicle.organization_data.data}
                  onSubmit={this.onSubmitOrganizationData}
                />
              )
            }
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    buttonContainer: {
      marginTop: 16,
      marginBottom: 16,
    },
    mainContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '75%',
    },
    traficomSource: {
      fontFamily: 'TitilliumWeb-Regular',
      color: '#ffffff',
      textDecoration: 'none solid rgb(255, 255, 255)',
      fontSize: 12,
      textAlign: 'right',
      paddingTop: 11,
      paddingRight: 20,
      paddingBottom: 16,
    }
  },
  small: {
    mainContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '100%',
    },
  },
};

function mapStateToProps(state) {
  return {
    selectedOrganizationId: state.organization.selectedOrganization,
  };
}

export default connect(
  mapStateToProps,
  {
    updateOrganizationVehicle: _updateOrganizationVehicle,
  },
)(translate('VehicleDetailsView')(VehicleDetailsBasicContent));
