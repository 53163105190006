import React, { useState } from 'react';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonGarageAction from '../../shared/CBButtonGarageAction';
import ChangeTaskStatusesModal from './ChangeTaskStatusesModal';

export default function TaskMultiSelectActionBar({ selectedTasks }) {
  const [statusModalIsOpen, setStatusModalIsOpen] = useState(false);

  return (
    <CBMediaQuery>
      {screenSize => (
        <div style={getStyle(screenSize, styles, 'container')}>
          <div style={getStyle(screenSize, styles, 'filterContainer')}>
            <React.Fragment>
              <p style={styles.default.text}>{selectedTasks.length} valittu</p>
              <CBButtonGarageAction
                mainIcon='/img/icon_edit.svg'
                additionalStylesMainIcon={{ height: 25, width: 25 }}
                text="Muuta tilaa..."
                onClick={() => setStatusModalIsOpen(true)}
                disabled={selectedTasks.length === 0}
                />
            </React.Fragment>
          </div>
          <ChangeTaskStatusesModal
              modalIsOpen={statusModalIsOpen}
              closeModal={() => setStatusModalIsOpen(false)}
              selectedTaskIds={selectedTasks.map(task => task.id)}
          />
        </div>
      )}
    </CBMediaQuery>
  )
}


const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
      padding: 8,
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
      alignItems: 'center',
    },
    text: {
      margin: 0,
    }
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      padding: 8,
    },
  }
}