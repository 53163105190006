import React, { Fragment } from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { fetchVehicle as _fetchVehicle, createVehicleImage as _createVehicleImage } from '../../actions/vehicleActions';
import CBSpinner from '../shared/CBSpinner';
import VehicleDetailsTop from './VehicleDetailsComponents/TopSection/VehicleDetailsTop';
import VehicleDetailsNavbar from './VehicleDetailsComponents/VehicleDetailsNavbar';
import VehicleDetailsMainBasic from './VehicleDetailsComponents/VehicleDetailsMainBasic';
import VehicleDetailsMainMaintenance from './VehicleDetailsComponents/VehicleDetailsMainMaintenance';
import VehicleDetailsMainTreatment from './VehicleDetailsComponents/VehicleDetailsMainTreatment';
import VehicleDetailsMainInspection from './VehicleDetailsComponents/VehicleDetailsMainInspection';
import WorkshopNotificationsList from '../shared/WorkshopNotificationsList';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle, SERVICE_FLEET, SERVICE_WORKSHOP } from '../../utils/utils';
import VehicleDetailsReminders from './VehicleDetailsComponents/Reminders/VehicleDetailsReminders';
import AddImageModal from '../shared/AddImageModal';
import VehicleDetailsMainMessage from './VehicleDetailsComponents/VehicleDetailsMainMessage';
import CBAdvertisementWrapper from '../shared/CBAdvertisementWrapper';
import VehicleDetailsMainTask from './VehicleDetailsComponents/VehicleDetailsMainTask';
import VehicleDetailsMainSaleReport from './VehicleDetailsComponents/VehicleDetailsMainSaleReport';
import VehicleDetailsMainMileage from './VehicleDetailsComponents/VehicleDetailsMainMileage';

class VehicleDetailsView extends React.Component {
  constructor() {
    super();

    this.state = {
      addImageModal: false,
      addedImage: null,
    };
  }

  componentWillMount = () => {
    const {
      match,
      fetchVehicle,
      selectedWorkshopId,
      selectedOrganizationId,
      service,
    } = this.props;

    const params = {};

    if (service === SERVICE_FLEET) {
      params.organization_id = selectedOrganizationId;
    } else if (service === SERVICE_WORKSHOP) {
      params.workshop_id = selectedWorkshopId;
    }

    fetchVehicle(match.params.vehicleId, params);
  };

  navigateBack = () => {
    const { history, lastListUrl } = this.props;

    if (lastListUrl) {
      history.push(lastListUrl);
    } else {
      history.goBack();
    }
  }

  hideModal = () => {
    this.setState({
      addImageModal: false,
    });
  }

  onImageUpload = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          addedImage: reader.result,
          addImageModal: true,
        });
      };
    });
  };

  uploadImage = (image) => {
    const { createVehicleImage, vehicleRequest } = this.props;
    this.setState({ addImageModal: false });
    createVehicleImage({ image: image, vehicle: vehicleRequest.vehicle.id });
  }

  render() {
    const { vehicleRequest, user, service, selectedOrganizationId, lastListUrl } = this.props;
    const { addImageModal, addedImage } = this.state;

    const currentOrganization = user.organizations.find((org) => selectedOrganizationId == org.id);
    const isUnpaidFleetUser = service === SERVICE_FLEET && currentOrganization && !currentOrganization.paid_subscription;

    const hasFeatureTasksAndMessages = (service === SERVICE_FLEET && currentOrganization && currentOrganization.feature_tasks_and_messages_enabled) || service === SERVICE_WORKSHOP;

    if (!vehicleRequest.vehicle) {
      return <CBSpinner />;
    } else if (vehicleRequest.vehicleStatus.error) {
      return (
        <p>
          {vehicleRequest.vehicleStatus.error}
        </p>
      );
    } else {
      const { vehicle } = vehicleRequest;
      return (
        <Fragment>
          <CBMediaQuery>
            {screenSize => (
              <div style={getStyle(screenSize, styles, 'detailsContainer')}>
                <VehicleDetailsTop
                  isUploadingImage={vehicleRequest.imageStatus.loading}
                  onImageUpload={this.onImageUpload}
                  vehicle={vehicle}
                  user={user}
                  navigateBack={this.navigateBack}
                  showNavigateBack={!!lastListUrl}
                />
                <VehicleDetailsNavbar user={user} vehicle={vehicle} service={service} isUnpaidFleetUser={isUnpaidFleetUser} hasFeatureTasksAndMessages={hasFeatureTasksAndMessages} />
                <div style={getStyle(screenSize, styles, 'mainContentContainer')}>
                  <div style={getStyle(screenSize, styles, 'mainContentLeft')}>
                    {
                      isUnpaidFleetUser && (
                        <Route
                          exact
                          path='/vehicle/:vehicleId/'
                          render={() => <VehicleDetailsMainMaintenance vehicle={vehicle} />}
                        />
                      )
                    }
                    {
                      !isUnpaidFleetUser && (
                        <Route
                          exact
                          path='/vehicle/:vehicleId/'
                          render={() => <VehicleDetailsMainBasic vehicle={vehicle} />}
                        />
                      )
                    }
                    <Route
                      path='/vehicle/:vehicleId/basic'
                      render={() => <VehicleDetailsMainBasic vehicle={vehicle} />}
                    />
                    <Route
                      path='/vehicle/:vehicleId/maintenances'
                      render={() => <VehicleDetailsMainMaintenance vehicle={vehicle} />}
                    />
                    <Route
                      path='/vehicle/:vehicleId/treatments'
                      render={() => <VehicleDetailsMainTreatment vehicle={vehicle} />}
                    />
                    <Route
                      path='/vehicle/:vehicleId/inspections'
                      render={() => <VehicleDetailsMainInspection vehicle={vehicle} />}
                    />
                    <Route
                      path='/vehicle/:vehicleId/sale_report'
                      render={() => <VehicleDetailsMainSaleReport vehicle={vehicle} />}
                    />

                    <Route
                      path='/vehicle/:vehicleId/mileages'
                      render={() => <VehicleDetailsMainMileage vehicle={vehicle} />}
                    />

                    {
                      hasFeatureTasksAndMessages && (
                        <React.Fragment>
                          <Route
                            path='/vehicle/:vehicleId/messages'
                            render={() => <VehicleDetailsMainMessage vehicle={vehicle} />}
                          />
                          <Route
                            path='/vehicle/:vehicleId/tasks'
                            render={() => <VehicleDetailsMainTask vehicle={vehicle} />}
                          />
                        </React.Fragment>
                      )
                    }

                  </div>
                  <div style={getStyle(screenSize, styles, 'reminderContainer')}>
                    {screenSize !== 'large' && <VehicleDetailsReminders vehicle={vehicle} />}
                  </div>
                  <div style={getStyle(screenSize, styles, 'mainContentRight')}>
                    <WorkshopNotificationsList />
                    <CBAdvertisementWrapper
                      style={getStyle(
                        screenSize,
                        styles,
                        'advertisementContainer'
                      )}
                      location="vehicle_page"
                      header="Mainoksia"
                    />
                  </div>
                </div>
              </div>
            )}
          </CBMediaQuery>
          {addImageModal && (
            <AddImageModal
              show={addImageModal}
              handleClose={this.hideModal}
              image={addedImage}
              onImageSave={(image) => { this.uploadImage(image); }}
            />
          )}
        </Fragment>
      );
    }
  }
}

const styles = {
  default: {
    mainContentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    mainContentLeft: {
      flexBasis: '70%',
    },
    mainContentRight: {
      flexBasis: '30%',
    },
    advertisementContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  },
  medium: {
    mainContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'normal',
    },
    advertisementContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  },
  small: {
  },
};

function mapStateToProps(state) {
  return {
    vehicleRequest: state.vehicle,
    user: state.authUser.user,
    service: state.app.service,
    selectedOrganizationId: state.organization.selectedOrganization,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    lastListUrl: state.app.lastListUrl,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchVehicle: _fetchVehicle,
    createVehicleImage: _createVehicleImage,
  },
)(VehicleDetailsView);
