import React from 'react';
import { translate } from 'react-i18next';
import LicensePlate from '../../../shared/LicensePlate';
import { getStyle, formatDate, SERVICE_FLEET, SERVICE_WORKSHOP } from '../../../../utils/utils';
import EditVehicleTopInfoModal from '../Modal/EditVehicleTopInfoModal';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

class VehicleDetailsTopInfo extends React.Component {
  state = {
    modalIsOpen: false,
  };

  showModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  navigateToVehicle = (vehicle) => {
    window.location.replace(`/vehicle/${vehicle.id}/basic`);
  }

  render() {
    const { t, vehicle, screenSize, user, service } = this.props;
    const { modalIsOpen } = this.state;

    const hasNextCheck = !!vehicle.next_check;

    const hasChildren = vehicle.children && vehicle.children.length > 0;

    let childrenString = '';

    if (hasChildren) {
      childrenString = vehicle.children.map((child) => {
        return `${child.manufacturer} ${child.model_name || ''} (${child.registration_number})`;
      }).join(', ');
    }

    return (
      <div style={getStyle(screenSize, styles, 'infoContainer')}>
        <div style={getStyle(screenSize, styles, 'infoTopContainer')}>
          <p style={getStyle(screenSize, styles, 'detailsText')} className='font-titillium-web'>
            {vehicle.vehicle_class}
          </p>
          <img
            alt=''
            src='/img/line.svg'
            style={getStyle(screenSize, styles, 'separator')}
          />
          <p style={getStyle(screenSize, styles, 'detailsText')} className='font-titillium-web'>
            {'vm. ' + (vehicle.year_model || '')}
          </p>
          <img
            alt=''
            src='/img/line.svg'
            style={getStyle(screenSize, styles, 'separator')}
          />
        </div>
        <div>
          <p className='font-titillium-web-light' style={getStyle(screenSize, styles, 'mainLabel')}>
            {`${vehicle.manufacturer} ${vehicle.model_name || ''}`}
          </p>
          {
            vehicle.parent && (
              <p className='font-titillium-web-light hover-underline' style={getStyle(screenSize, styles, 'attachedToLabel')} onClick={() =>  {this.navigateToVehicle(vehicle.parent)}}>
                {`${t('attachedToVehicle')} ${vehicle.parent.manufacturer} ${vehicle.parent.model_name || ''} (${vehicle.parent.registration_number})`}
              </p>
            )
          }
          <p
            className='font-titillium-web-extra-light'
            style={getStyle(screenSize, styles, 'subLabel')}
          >
            {vehicle.owner_description}
          </p>
        </div>
        <div style={getStyle(screenSize, styles, 'licensePlateContainer')}>
          <LicensePlate size='large' plateNumber={vehicle.registration_number} />
        </div>
        {
          vehicle.organization_data && vehicle.organization_data.identifier && (
            <p style={getStyle(screenSize, styles, 'smallInfoText')}>
              <span className='font-titillium-web-light'>
                {`${t('identifier')} `}
              </span>
              <span className='font-titillium-web-semi-bold'>
                {vehicle.organization_data.identifier}
              </span>
            </p>
          )
        }

        {
          vehicle.organization_data && vehicle.organization_data.maintenance_interval_months && (
            <p style={getStyle(screenSize, styles, 'smallInfoText')}>
              <span className='font-titillium-web-light'>
                {`${t('maintenanceIntervalMonths')} `}
              </span>
              <span className='font-titillium-web-semi-bold'>
                {vehicle.organization_data.maintenance_interval_months}
              </span>
            </p>
          )
        }

        {
          vehicle.organization_data && vehicle.organization_data.maintenance_interval_km && (
            <p style={getStyle(screenSize, styles, 'smallInfoText')}>
              <span className='font-titillium-web-light'>
                {`${t('maintenanceIntervalKm')} `}
              </span>
              <span className='font-titillium-web-semi-bold'>
                {vehicle.organization_data.maintenance_interval_km}
              </span>
            </p>
          )
        }

        {
          vehicle.mileages && vehicle.mileages.length > 0 && (
            <p style={getStyle(screenSize, styles, 'smallInfoText')}>
              <span className='font-titillium-web-light'>
                {`${t('kilometersInfobox')} `}
              </span>
              <span className='font-titillium-web-semi-bold'>
              {
                vehicle.mileages.reduce((latest, obj) =>
                  new Date(obj.read_at) > new Date(latest.read_at) ? obj : latest, vehicle.mileages[0]
                ).value
              }
              </span>
            </p>
          )
        }

        <p style={getStyle(screenSize, styles, 'smallInfoText')}>
          <span className='font-titillium-web-light'>
            {`${t('inspectionInterval')} `}
          </span>
          <span className='font-titillium-web-semi-bold'>
            {formatDate(vehicle.inspection_interval_begin)}
            {' - '}
            {formatDate(vehicle.inspection_interval_end)}
          </span>
        </p>
        <p style={getStyle(screenSize, styles, 'smallInfoText')}>
          <span className='font-titillium-web-light'>
            {`${t('introductionDate')} `}
          </span>
          <span className='font-titillium-web-semi-bold'>
            {
              (vehicle.introduction_date
                ? formatDate(vehicle.introduction_date)
                : vehicle.introduction_year) || '-'
            }
          </span>
        </p>
        {
          (service === SERVICE_FLEET || service === SERVICE_WORKSHOP) && (
            <div style={getStyle(screenSize, styles, 'organizationDataContainer')}>
              <div style={getStyle(screenSize, styles, 'tachoGraphContainer')}>
                <p style={getStyle(screenSize, styles, 'smallInfoText')}>
                  <span className='font-titillium-web-light'>
                    {
                      hasNextCheck ? `${vehicle.next_check.name} ` : `${t('nextCheckDate')} `
                    }
                  </span>
                  <span className='font-titillium-web-semi-bold'>
                    {
                      hasNextCheck ? formatDate(vehicle.next_check.date) : '-'
                    }
                  </span>
                </p>
              </div>
              <div style={getStyle(screenSize, styles, 'organizationStatusContainer')}>
                <div style={getStyle(screenSize, styles, 'organizationStatusSubContainer')}>
                  <p style={getStyle(screenSize, styles, 'smallInfoText')}>
                    <span className='font-titillium-web-light'>
                      {`${t('employee')} `}
                    </span>
                    <span className='font-titillium-web-semi-bold'>
                      {
                        (vehicle.organization_vehicle_status
                        && vehicle.organization_vehicle_status.employee) || '-'
                      }
                    </span>
                  </p>
                </div>
                <div style={getStyle(screenSize, styles, 'organizationStatusSubContainer')}>
                  <p style={getStyle(screenSize, styles, 'smallInfoText')}>
                    <span className='font-titillium-web-light'>
                      {`${t('businessUnit')} `}
                    </span>
                    <span className='font-titillium-web-semi-bold'>
                      {
                        (vehicle.organization_vehicle_status
                          && vehicle.organization_vehicle_status.business_unit) || '-'
                      }
                    </span>
                  </p>
                </div>
              </div>
              {
                hasChildren && (
                  <div>
                    <p style={getStyle(screenSize, styles, 'smallInfoText')}>
                      <span className='font-titillium-web-light'>
                        {`${t('attachedVehicles')} `}
                      </span>
                      {
                        vehicle.children.map((child, idx) => {
                          return (
                            <span
                              className='font-titillium-web-semi-bold hover-underline'
                              style={getStyle(screenSize, styles, 'attachedVehicleText')}
                              onClick={() => { this.navigateToVehicle(child); }}
                            >
                              {`${child.manufacturer} ${child.model_name || ''} (${child.registration_number})${idx === vehicle.children.length - 1 ? '' : ', '}`}
                            </span>
                          );
                        })
                      }
                    </p>
                  </div>
                )
              }
            </div>
          )
        }

        <div style={getStyle(screenSize, styles, 'bottomBlock')}>
          <p
            style={getStyle(screenSize, styles, 'detailsText')}
            className='font-titillium-web-light'
          >
            {t('traficomSource')}
          </p>
          {vehicle.can_edit && (
            <div onClick={this.showModal}>
              <img
                src='/img/icon_edit.svg'
                alt='Edit'
                style={getStyle(screenSize, styles, 'editIcon')}
              />
            </div>
          )}
        </div>
        <EditVehicleTopInfoModal
          isOrganizationVehicle={service === SERVICE_FLEET || service === SERVICE_WORKSHOP}
          modalIsOpen={modalIsOpen}
          closeModal={this.closeModal}
          vehicle={vehicle}
        />
      </div>
    );
  }
}

const styles = {
  default: {
    bottomBlock: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    organizationDataContainer: {
    },
    tachoGraphContainer: {
    },
    organizationStatusContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    organizationStatusSubContainer: {
      paddingRight: 8,
    },
    detailsText: {
      opacity: 0.7,
      fontSize: 12,
      color: '#FFF',
      marginRight: 8,
      paddingRight: 0,
    },
    editIcon: {
      height: 24,
      width: 24,
      opacity: 0.5,
      cursor: 'pointer',
    },
    smallInfoText: {
      color: '#FFF',
      fontSize: 14,
    },
    mainLabel: {
      fontSize: 32,
      color: '#ffffff',
      fontWeight: 300,
      marginBottom: 16,
      lineHeight: 1,
    },
    attachedToLabel: {
      fontSize: 24,
      color: '#ffffff',
      fontWeight: 300,
      marginBottom: 16,
      lineHeight: 1,
      cursor: 'pointer',
    },
    attachedVehicleText: {
      cursor: 'pointer',
    },
    licensePlateContainer: {
      marginBottom: 16,
    },
    subLabel: {
      fontSize: 20,
      color: '#ffffff',
      marginBottom: 16,
      fontWeight: 200,
      minWidth: '100%',
      width: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    separator: {
      transform: 'rotate(90deg)',
      opacity: 0.2,
      width: 25,
      height: 11,
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
      flexGrow: 1,
    },
    infoTopContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      justifyContent: 'flex-start',
    },
  },
  medium: {
  },
  small: {
    infoTopContainer: {
      flexDirection: 'row',
    },
  },
};


function mapStateToProps(state) {
  return {
    service: state.app.service,
  };
}

export default connect(
  mapStateToProps,
  {},
)(translate('VehicleDetailsView')(VehicleDetailsTopInfo));
