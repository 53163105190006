import React, { Component } from 'react';
import CBModal from './CBModal';
import CBInputField from './form_components/CBInputField';
import CBButtonSubmit from './CBButtonSubmit';
import CBMediaQuery from './CBMediaQuery';
import { translate } from 'react-i18next';
import { getStyle } from '../../utils/utils';

class KeyValueEditorModal extends Component {
  state = {
    formData: Object.entries(this.props.initialData || {}).map(([key, value]) => ({ key, value })),
  };

  handleInputChange = (index, field, newValue) => {
    this.setState(prevState => {
      const updatedFormData = [...prevState.formData];
      updatedFormData[index][field] = newValue;
      return { formData: updatedFormData };
    });
  };

  handleAddField = () => {
    this.setState(prevState => ({
      formData: [...prevState.formData, { key: '', value: '' }],
    }));
  };

  handleRemoveField = (index) => {
    this.setState(prevState => {
      const updatedFormData = prevState.formData.filter((_, i) => i !== index);
      return { formData: updatedFormData };
    });
  };

  handleSubmit = () => {
    const { onSubmit, closeModal } = this.props;
    const output = Object.fromEntries(this.state.formData.map(({ key, value }) => [key, value]));
    onSubmit(output);
    closeModal();
  };

  render() {
    const { formData } = this.state;
    const { modalIsOpen, closeModal, afterOpenModal, t, title } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title={title}
            cancelText={'Peruuta'}
            submitText={'Tallenna'}
            submitForm={this.handleSubmit}
            isValid={true}
          >
            <div style={{ padding: '16px' }}>
              {formData.map((pair, index) => (
                <div key={index} style={getStyle(screenSize, styles, 'container')}>
                  <CBInputField
                    name={`key-${index}`}
                    value={pair.key}
                    placeholder="Kentän nimi"
                    onChange={e => this.handleInputChange(index, 'key', e.value)}
                    inputStyle={getStyle(screenSize, styles, 'input')}
                    labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                  />
                  <CBInputField
                    name={`value-${index}`}
                    value={pair.value}
                    placeholder="Kentän arvo"
                    onChange={e => this.handleInputChange(index, 'value', e.value)}
                    inputStyle={getStyle(screenSize, styles, 'input')}
                    labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                  />
                  <CBButtonSubmit
                    style={getStyle(screenSize, styles, 'button')}
                    onClick={() => this.handleRemoveField(index)}
                    text="Poista kenttä"
                    textStyle={getStyle(screenSize, styles, 'buttonText')}
                  />
                </div>
              ))}
              <CBButtonSubmit
                style={getStyle(screenSize, styles, 'button')}
                onClick={this.handleAddField}
                text="Lisää kenttä"
                textStyle={getStyle(screenSize, styles, 'buttonText')}
              />

            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
    default: {
        input: {
        backgroundColor: '#ebebeb',
        fontSize: 14,
        textAlign: 'center',
        resize: 'none',
        color: 'black',
        borderRadius: 100,
        height: 40,
        border: 'none',
        width: '100%',
        },
        buttonText: {
          fontSize: 14,
          fontWeight: 700,
          textDecoration: 'none solid rgb(0, 246, 0)',
          color: '#0095da',
          padding: 8,
          margin: 0
        },
        button: {
          width: 200,
          border: '2px solid #0095da50',
          color: '#0095da',
          borderRadius: 50,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        },
        inputLabelText: {
        color: 'black',
        },
        container: {
        marginBottom: '12px',
        display: 'flex',
        alignItems: 'center',
        gap: 8
        }
    },
};

export default translate('KeyValueEditorModal')(KeyValueEditorModal);
